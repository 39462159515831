<template>
  <section class="page-news-latest">
    <!-- Title -->
    <div class="row-1 flex --justify-space-between --align-center">
      <h2 class="title-m m-right-16px" v-anim-reveal-text>
        {{ $t('pages.news.latestTitle') }}
      </h2>

      <WidgetLinkMainUnderline
        :link="{ key: 'view-all', route: { name: 'news' } }"
      />
    </div>

    <template v-if="viewport > 1000 && rendered">
      <!-- Statiche-->
      <div
        class="m-top-32px text-m page-news-latest__grid row-1 show-for-large"
        v-anim-parallax-element="{
          target: 'picture',
          offset: 5,
        }"
      >
        <WidgetContentBlock
          v-for="news in formattedNews.slice(0, 3)"
          :key="news.id"
          :content="news"
        />
      </div>
    </template>
    <template v-else-if="rendered">
      <!-- Slider-->
      <div
        class="m-top-32px text-m page-news-latest__swiper hide-for-large"
        v-anim-parallax-element="{
          target: 'picture',
          offset: 5,
        }"
      >
        <WidgetSliderSwiper :slides="formattedNews" />
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: 'PagesNewsLatest',
}
</script>

<script setup>
const props = defineProps({
  news: Object,
})

const { locale, t } = useI18n()

const viewport = ref(0)
const rendered = ref(false)

const formattedNews = computed(() => {
  return props.news.map((news) => ({
    id: news.id,
    title: news.title,
    pretitle: t(`filters.${news.newsType}`),
    aftertitle: new Intl.DateTimeFormat(locale.value).format(
      new Date(news.postDate)
    ),
    cover: news.cover?.[0],
    route: {
      name: 'news-slug',
      params: { slug: news.slug },
    },
  }))
})

onMounted(() => {
  updateViewportSize()
  rendered.value = true
  window.addEventListener('resize', updateViewportSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateViewportSize)
})

const updateViewportSize = () => {
  viewport.value = window.innerWidth
}
</script>

<style lang="scss">
@import './style.scss';
</style>
